<template>
  <div class="c-app">
    <TheSidebar/>
    <CWrapper>
<!--      <TheHeader/>-->
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
<!--      <TheFooter/>-->
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from './TheSidebar'

export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.c-main {
  padding-top: 20px;
  padding-left: 20px;
}
</style>
<style>
  .c-main .vue2leaflet-map {
    margin-top: -20px;
    margin-left: -20px;
    min-height: calc(100% + 20px);
    min-width: calc(100% + 20px);
  }

</style>
