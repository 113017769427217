<template>
  <CSidebar
    fixed 
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <div>Админ</div>
      <CIcon
        class="c-sidebar-brand-full"
        name="burn"
        size="custom-size"
        :height="35"
      />
      <CIcon
        class="c-sidebar-brand-minimized"
        name="burn"
        size="custom-size"
        :height="35"
      />
    </CSidebarBrand>
    <CRenderFunction flat :content-to-render="$options.nav"/>
    <CButton
            color="primary"
            class="my-4 w-75 mx-auto px-4 d-flex align-items-center justify-content-center c_btn"
            v-if="is22"
            to="/results_22"
            component="a"
    >
        <CIcon name="cil-clock"/>
        <span class="text">Отчет 22</span>
    </CButton>

    <CButton
      color="secondary"
      class="my-4 w-75 mx-auto px-4 d-flex align-items-center justify-content-center c_btn"
      v-if="isLoggedIn"
      @click="logout"
    >
        <CIcon name="cil-exit-to-app"/>
        <span class="text">Выйти</span>
    </CButton>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from './_nav'

export default {
  name: 'TheSidebar',
  nav,
  computed: {
    isLoggedIn : function(){ return this.$store.getters.isAuthenticated},
      is22: function(){return this.$store.getters.userRole >= "3"},
    show () {
      return this.$store.state.sidebarShow 
    },
    minimize () {
      return this.$store.state.sidebarMinimize 
    }
  },
  methods: {
    async logout (){
      await this.$store.dispatch('LogOut')
      await this.$router.push('/login')
    },
  },
}
</script>

<style>
    .c_btn {
        gap: 8px;
    }
    .c_btn .text {
        white-space: nowrap;
    }
    .c-sidebar-minimized {
        padding-bottom: 50px;
    }
    .c-sidebar-minimized .c_btn .text {
        display: none;
    }
    .c-icon {
        min-width: 1rem;
    }
</style>